<template lang="html">
  <div class="page">
    <h1><w-icon>mdi mdi-lightbulb-on-outline</w-icon> ミヒラク☆プリント とは？</h1>
    <div class="content">
      <h2>Point 1. 素早く作れるから 心を惹きつけます</h2>
      <AccessMind/>
      <h2>Point 2. 手書き文字を 自動で認識します</h2>
      <AutoDetect/>
      <h2>Point 3. 再学習を 自動でスケジュールします</h2>
      <div class="point-3">
        <img src="/image/page/service/print/overview/re-learning.png" alt="自動スケジュール機能の概要図">
      </div>
      <hr>
      <w-button lg
        bg-color="primary" target="_blank" rel="noopener noreferrer"
        route="https://print.mihiraku.com/">
        使ってみる
        <w-icon>wi-chevron-right</w-icon>
      </w-button>
    </div>
    <p class="next-comment">
      <b>無料</b>で お使いいただけます<br>
      良心的な「
      <router-link to="/service/print/policy/price/">価格</router-link>
      」で 目一杯 使うこともできます<br><br>
      <router-link to="/service/print/policy/trading/">特定商取引法に基づく表記</router-link><br>
      <router-link to="/service/print/policy/privacy/">個人情報の取り扱い</router-link><br>
      <router-link to="/service/print/policy/user/">利用規約</router-link><br>
    </p>
  </div>
</template>

<script>
import AccessMind from './AccessMind.vue'
import AutoDetect from './AutoDetect.vue'
export default {
  components: {
    AccessMind,
    AutoDetect
  }
}
</script>

<style lang="scss" scoped>
</style>
