<template lang="html">
  <div class="app">
    <table>
      <tr>
        <td class="top">Browser</td>
      </tr>
      <tr>
        <td class="bottom">
          <div class="paper">
            <table>
              <tr>
                <td :style="{ backgroundColor: isShowColor ? colors[map[0]] : null }">
                  <div>
                    <span v-if="map[0] === 1"><w-icon color="info">mdi mdi-cursor-pointer</w-icon></span>
                    <span v-else-if="map[0] === 2">動</span>
                    <span v-else-if="map[0] === 3">同</span>
                  </div>
                </td>
                <td rowspan="3">
                  <div>
                    <span>ど</span>
                    <span>う</span>
                    <span>ぶ</span>
                    <span>つ</span>
                    <span>え</span>
                    <span>ん</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td :style="{ backgroundColor: isShowColor ? colors[map[1]] : null }">
                  <div>
                    <span v-if="map[1] === 1"><w-icon color="info">mdi mdi-cursor-pointer</w-icon></span>
                    <span v-else-if="map[1] === 2">物</span>
                    <span v-else-if="map[1] === 3">持</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td :style="{ backgroundColor: isShowColor ? colors[map[2]] : null }">
                  <div>
                    <span v-if="map[2] === 1"><w-icon color="info">mdi mdi-cursor-pointer</w-icon></span>
                    <span v-else-if="map[2] === 2">園</span>
                    <span v-else-if="map[2] === 3">遠</span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </td>
      </tr>
    </table>
    <div class="control">
      <w-switch v-model="animation" :label="animation ? 'On' : 'Off'"></w-switch>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animation: true,
      map: [0, 0, 0],
      colors: [null, null, '#8defff', '#ffa5a5'],
      isShowColor: false,
      indexes: {
        writeTarget: 0,
        failedTarget: 0
      }
    }
  },
  watch: {
    animation(value) {
      if (value) this.start()
      else this.end()
    }
  },
  mounted() {
    this.start()
  },
  methods: {
    start() {
      if (!this.timer) {
        this.timer = setInterval(this.process.bind(this), 1000)
      }
    },
    end() {
      clearInterval(this.timer)
      this.timer = null
    },
    reset() {
      this.indexes.failedTarget = ++this.indexes.failedTarget % 3
      this.indexes.writeTarget = 0
      this.isShowColor = false
      this.map = [0, 0, 0]
    },
    process() {
      const wi = this.indexes.writeTarget
      const fi = this.indexes.failedTarget
      const value = this.map[wi]
      if (value == null) {
        if (!this.isShowColor) this.isShowColor = true
        else this.reset()
      }
      else if (value === 0) this.map[wi] = 1
      else if (value === 1) {
        this.map[wi] = wi === fi ? 3 : 2
        this.indexes.writeTarget++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&family=Zen+Kurenaido&display=swap');
</style>

<style lang="scss" scoped>
.app {
  $browserRadius: 0.5rem;
  $seeHeight: 30vh;
  $seeHeightLength: 3;
  $paperFontSize: calc(#{$seeHeight} / #{$seeHeightLength});
  $boxSize: calc(#{$paperFontSize} * 1.25);
  $huriganaFontSize: calc(#{$paperFontSize} * 0.5);
  $huriganaPaddingLeft: calc(#{$huriganaFontSize} * 0.1);
  $huriganaSpase: calc(#{$huriganaFontSize} + #{$huriganaPaddingLeft});
  div.control {
    text-align: right;
  }
  // hand
  .w-icon {
    font-size: unset;
    font-size: 80%;
  }
  // browser
  table {
    width: inherit;
    text-align: center;
    margin: auto;
    td {
      padding: 0.25rem;
      &.top {
        border-top-left-radius: $browserRadius;
        border-top-right-radius: $browserRadius;
        background-color: #6c8693;
        color: white;
      }
      &.bottom {
        background-color: #e2e7e9;
        border-bottom-left-radius: $browserRadius;
        border-bottom-right-radius: $browserRadius;
        .paper {
          padding: calc(#{$paperFontSize} * 0.25);
          font-size: $paperFontSize;
          background-color: white;
          height: 100%;
          table {
            tr {
              td:first-child {
                font-family: 'Hachi Maru Pop', cursive;
                width: $boxSize;
                height: $boxSize;
                border: solid;

                span {
                  // line-height: 1px;
                  // 参考 : https://yuyakinoshita.com/blog/2020/01/20/line-height-crop/
                  &::before {
                    display: block;
                    width: 0;
                    height: 0;
                    margin-top: -0.3em;
                    content: "";
                  }
                  &::after {
                    display: block;
                    width: 0;
                    height: 0;
                    margin-bottom: -0.1em;
                    content: "";
                  }
                }
              }
              td+td {
                font-size: $huriganaFontSize;
                vertical-align: top;
                padding: 0;
                padding-left: $huriganaPaddingLeft;
                div {
                  display: flex;
                  flex-direction: column;
                  span {
                    line-height: $huriganaFontSize;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
