<template>
  <table>
    <tr v-for="(k, i) in kanji" :key="i">
      <td>
        <span>{{ k }}</span>
      </td>
      <td v-if="i === 0" :rowspan="kanji.length">
        <div>
          <span v-for="(y, i) in yomi" :key="i">{{ y }}</span>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    kanji: String,
    yomi: String
  }
}
</script>

<style lang="scss" scoped>
</style>
