<template>
  <div class="app">
    <table>
      <tr>
        <td colspan="2" class="top">Browser</td>
      </tr>
      <tr>
        <td class="left">
          <div class="text-wrap">
            <p class="input">{{ struct.inputed }}<span>{{ struct.input }}</span></p>
          </div>
        </td>
        <td class="right">
          <div class="paper-wrap">
            <div class="paper">
              <div v-for="(word, i) in struct.words" :key="i">
                <div v-for="(chars, j) in word" :key="j">
                  <div v-if="typeof(chars) === 'string'">
                    <div v-for="(char, k) in chars" :key="k">
                      <span>{{ char }}</span>
                    </div>
                  </div>
                  <Write v-else :kanji="chars.kanji" :yomi="chars.yomi"/>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div class="control">
      <w-switch v-model="animation" :label="animation ? 'On' : 'Off'"></w-switch>
    </div>
  </div>
</template>

<script>
import Write from './Write.vue'
export default {
  components: {
    Write
  },
  data() {
    return {
      animation: true,
      plans: [
        { input: ['おかあさん', 'お母さん'], word: ['お', { kanji: '母', yomi: 'かあ' }, 'さん'] },
        { input: ['からの'], word: ['からの'] },
        { input: ['おてがみ', 'お手紙'], word: ['お', { kanji: '手紙', yomi: 'てがみ' }] },
        { input: ['だよ。'], word: ['だよ。'] },
        { input: ['きょうは', '今日は'], word: [{ kanji: '今日', yomi: 'きょう' }, 'は'] },
        { input: ['どうぶつえん', '動物園'], word: [{ kanji: '動物園', yomi: 'どうぶつえん' }] },
        { input: ['とっても'], word: ['とっても'] },
        { input: ['たのしかったね', '楽しかったね'], word: [{ kanji: '楽', yomi: 'たの' }, 'しかったね'] },
        { input: ['ぞうさんは', '象さんは'], word: [{ kanji: '象', yomi: 'ぞう' }, 'さんは'] },
        { input: ['はなで', '鼻で'], word: [{ kanji: '鼻', yomi: 'はな' }, 'で'] },
        { input: ['みずあび', '水浴び'], word: [{ kanji: '水浴', yomi: 'みずあ' }, 'び'] },
        { input: ['していたね。'], word: ['していたね。'] },
      ],
      indexes: {
        planIndex: null,
        stepIndex: null,
        charIndex: null,
      },
      struct: {
        inputed: '',
        input: '',
        words: [],
      }
    }
  },
  watch: {
    animation(value) {
      if (value) this.start()
      else this.end()
    }
  },
  mounted() {
    this.reset()
    this.start()
  },
  methods: {
    reset() {
      const i = this.indexes
      i.planIndex = 0
      i.stepIndex = 0
      i.charIndex = 0
      this.struct.inputed = ''
      this.struct.input = ''
      this.struct.words = []
    },
    start() {
      if (!this.timerInterval) {
        if (this.needReset) {
          this.reset()
          this.needReset = false
        }
        this.timerInterval = setInterval(this.process, 200)
      }
    },
    end() {
      clearInterval(this.timerInterval)
      clearTimeout(this.timerTimeout)
      this.timerInterval = null
      this.timerTimeout = null
    },
    process() {
      const plan = this.plans[this.indexes.planIndex]
      if (plan) {
        let loop = true
        do {
          loop = this[`step_${this.indexes.stepIndex}`](plan)
        } while(loop)
      } else {
        this.end()
        this.needReset = true
        this.timerTimeout = setTimeout(() => { this.start() }, 3000)
      }
    },
    step_0(plan) {
      if (this.indexes.charIndex < plan.input[0].length) {
        this.struct.input += plan.input[0][this.indexes.charIndex]
        this.indexes.charIndex++
      } else {
        this.indexes.charIndex = 0
        this.indexes.stepIndex++
      }
    },
    step_1(plan) {
      if (plan.input[1]) {
        this.struct.input = plan.input[1]
        this.indexes.stepIndex++
      } else {
        this.indexes.stepIndex += 2
        return true
      }
    },
    step_2(plan) {
      this.indexes.stepIndex++
      this.struct.input = `（${plan.input[0]}|${plan.input[1]}）`
    },
    step_3(plan) {
      this.indexes.stepIndex++
      this.struct.inputed += this.struct.input
      this.struct.input = ''
      this.struct.words.push(plan.word)
      this.indexes.stepIndex = 0
      this.indexes.planIndex++
    },
  }
}
</script>
<style lang="scss" scoped>
.app ::v-deep() {
  $browserRadius: 0.5rem;
  $seeHeight: 40vh;
  $seeHeightLength: 7;
  $paperHeightLength: 15;
  $paperFontSize: calc(#{$seeHeight} / #{$seeHeightLength});
  $paperHeight: calc(#{$paperFontSize} * #{$paperHeightLength});
  $boxSize: calc(#{$paperFontSize} * 1.25);
  $huriganaFontSize: calc(#{$paperFontSize} * 0.5);
  $huriganaPaddingLeft: calc(#{$huriganaFontSize} * 0.1);
  $huriganaSpase: calc(#{$huriganaFontSize} + #{$huriganaPaddingLeft});
  div.control {
    margin-top: 1rem;
    text-align: right;
  }
  p.input {
    margin: 0;
    padding: 0;
    text-align: left;
    span {
      background-color: #ffdead;
    }
  }
  // Browser
  table {
    width: 100%;
    text-align: center;
    td {
      padding: 0.25rem;
      &.top {
        border-top-left-radius: $browserRadius;
        border-top-right-radius: $browserRadius;
        background-color: #6c8693;
        color: white;
      }
      &.left, &.right {
        background-color: #e2e7e9;
      }
      &.left {
        width: 33%;
        border-bottom-left-radius: $browserRadius;
      }
      &.right {
        border-bottom-right-radius: $browserRadius;
      }
      .text-wrap, .paper-wrap {
        height: $seeHeight;
        overflow: auto;
        padding: 0.5em;
        line-height: 150%;
      }
      .paper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap-reverse;
        justify-content: flex-start;
        align-content: flex-start;
        background-color: #fcfcfc;
        font-size: $paperFontSize;
        height: $paperHeight;
        padding: calc(#{$paperFontSize} * 0.25);
        overflow: hidden;
        span {
          line-height: $paperFontSize;
        }
        // Write
        table {
          border-collapse: collapse;
          border-spacing: 0;
          margin: 0;
          margin-left: $huriganaSpase;
          padding: 0;
          width: unset;
          tr {
            // kanji
            td:first-child {
              border: solid;
              width: $boxSize;
              height: $boxSize;
              span {
                color: #dddddd;
                line-height: 1px;
              }
            }
            // hurigana
            td+td {
              font-size: $huriganaFontSize;
              vertical-align: top;
              padding: 0;
              padding-left: $huriganaPaddingLeft;
              div {
                display: flex;
                flex-direction: column;
                span {
                  line-height: $huriganaFontSize;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
